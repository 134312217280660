import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Alvearie/alvearie.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Health patterns are cloud agnostic reference implementations for the overall
`}<a parentName="p" {...{
        "href": "https://alvearie.io/architecture"
      }}>{`Alvearie architecture`}</a>{` that incorporate best practices using open technologies.`}</p>
    <p>{`Below are the initial patterns that incorporate parts of Alvearie along with other open technologies that can be
used to start building your own healthcare solutions using a common base of proven technology.`}</p>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Clinical Data Ingestion" href="https://github.com/Alvearie/health-patterns/tree/main/ingest" mdxType="ResourceCard">
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
        <p>{`The `}<em parentName="p">{`Clinical Ingestion`}</em>{` health pattern with optional `}<em parentName="p">{`Enrichment`}</em>{` is a cloud agnostic (has been run on IBM Cloud, AWS, Azure, Google), flexible approach to processing healthcare data and storing it into a FHIR server.
At the most basic level, the Ingestion pattern will read HL7 or FHIR data from a `}<a parentName="p" {...{
            "href": "https://kafka.apache.org"
          }}>{`Kafka`}</a>{` topic and use `}<a parentName="p" {...{
            "href": "https://github.com/apache/nifi"
          }}>{`NiFi`}</a>{` to orchestrate any desired conversion or validation, and then store the results into the `}<a parentName="p" {...{
            "href": "https://github.com/ibm/fhir"
          }}>{`FHIR Server`}</a></p>
        <p>{`Within the `}<a parentName="p" {...{
            "href": "https://github.com/apache/nifi"
          }}>{`NiFi`}</a>{` canvas, the `}<em parentName="p">{`Clinical Ingestion`}</em>{` health pattern will:`}</p>
        <ul>
          <li parentName="ul">{`Convert `}<a parentName="li" {...{
              "href": "https://github.com/LinuxForHealth/hl7v2-fhir-converter"
            }}>{`HL7 to FHIR using technology from LinuxForHealth`}</a></li>
          <li parentName="ul">{`Validate the FHIR data without storing it in the FHIR Server`}</li>
          <li parentName="ul">{`Put the converted/validated data on a Kafka topic for processing by the `}<em parentName="li">{`Enrichment`}</em>{` pattern`}</li>
          <li parentName="ul">{`Store the FHIR bundle into the `}<a parentName="li" {...{
              "href": "https://github.com/ibm/fhir"
            }}>{`FHIR Server`}</a></li>
          <li parentName="ul">{`Handle errors:`}
            <ul parentName="li">
              <li parentName="ul">{`In case of errors within the bundle, individual resources are retried`}</li>
              <li parentName="ul">{`Errors are reported back to the data integrator via the kafka topic`}</li>
            </ul>
          </li>
        </ul>
        <p>{`Get started using the `}<a parentName="p" {...{
            "href": "https://github.com/Alvearie/health-patterns/tree/main/ingest"
          }}>{`clinical data ingestion pattern today`}</a></p>
        <p />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Clinical Data Enrichment" href="https://github.com/Alvearie/health-patterns/tree/main/enrich" mdxType="ResourceCard">
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
        <p>{`The `}<em parentName="p">{`Clinical Enrichment`}</em>{` health pattern has been separated from the `}<em parentName="p">{`Ingestion`}</em>{` pattern.  It is still available to be deployed and run as part of ingestion, but it can also be set up on its own.
If it is run as part of `}<em parentName="p">{`Ingestion`}</em>{` it will Enrich the data as it comes into the environment, before it is stored in the FHIR Server.  It can also be run after the data is persisted in the FHIR server and configured to run on new or changed data.
The goal for this `}<em parentName="p">{`Enrichment`}</em>{` pattern is that the enriched data will be written to a FHIR server by default, but because these patterns are just reference implementations, they can be modified and consumed in a number of ways.`}</p>
        <p><em parentName="p">{`Enrichment`}</em>{` will read FHIR data from a `}<a parentName="p" {...{
            "href": "https://kafka.apache.org"
          }}>{`Kafka`}</a>{` topic and use `}<a parentName="p" {...{
            "href": "https://github.com/apache/nifi"
          }}>{`NiFi`}</a>{` to orchestrate the optional types of enrichment such as:`}</p>
        <ul>
          <li parentName="ul">{`Convert terminology using the FHIR Terminology Services`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/Alvearie/de-identification"
            }}>{`De-identify`}</a>{` the FHIR data`}</li>
          <li parentName="ul">{`Run an algorithm such as `}<a parentName="li" {...{
              "href": "https://github.com/Alvearie/health-analytics/tree/main/ascvd"
            }}>{`ASCVD`}</a></li>
          <li parentName="ul">{`Run NLP against the unstructured data such as `}<a parentName="li" {...{
              "href": "https://www.hl7.org/fhir/documentreference.html"
            }}>{`FHIR DocumentReference`}</a></li>
        </ul>
        <p>{`The flexibility of this approach allows the consumer to use enrichment at the appropriate time.  For instance, some of the enrichment could happen at Ingestion time (FHIR Terminology for  instance) prior to storing the data, but after the data is persisted in the FHIR Server, Enrichment can run again to only run NLP and/or an analtyic algorithm.`}</p>
        <p>{`To get started today, use the `}<a parentName="p" {...{
            "href": "https://github.com/Alvearie/health-patterns/tree/main/enrich"
          }}>{`clinical data enrichment pattern today`}</a></p>
        <p />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Quality Measure & Cohorting " href="https://github.com/Alvearie/health-patterns/tree/main/cohort" mdxType="ResourceCard">
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
        <p>{`The `}<em parentName="p">{`Clinical Data Cohorting`}</em>{` pattern provides a reference implementation that uses the `}<a parentName="p" {...{
            "href": "https://github.com/Alvearie/quality-measure-and-cohort-service"
          }}>{`quality measure & cohort service`}</a>{` to find patients within the FHIR server that match a given cohort as defined using `}<a parentName="p" {...{
            "href": "https://cql.hl7.org"
          }}>{`CQL`}</a>{`.`}</p>
        <p>{`Get started using the `}<a parentName="p" {...{
            "href": "https://github.com/Alvearie/health-patterns/tree/main/cohort"
          }}>{`cohorting pattern today`}</a></p>
        <p />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="launch" color="dark" title="Clinical Data Access" href="https://github.com/Alvearie/health-patterns/tree/main/data-access" mdxType="ResourceCard">
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
        <p>{`The `}<em parentName="p">{`Clinical Data Access`}</em>{` pattern shows how to configure access to the FHIR server for traditional access methods, in particular how to configure the FHIR server for `}<a parentName="p" {...{
            "href": "https://smarthealthit.org/"
          }}>{`SMART on FHIR`}</a>{` using `}<a parentName="p" {...{
            "href": "https://www.keycloak.org/"
          }}>{`Keycloak`}</a>{` and the `}<a parentName="p" {...{
            "href": "https://github.com/Alvearie/keycloak-extensions-for-fhir"
          }}>{`Keycloak extensions for FHIR`}</a></p>
        <p>{`Get started using the `}<a parentName="p" {...{
            "href": "https://github.com/Alvearie/health-patterns/tree/main/data-access"
          }}>{`Data Access pattern today`}</a></p>
        <p />
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={2} colLg={3} noGutterMdLeft mdxType="Column">
        <ResourceCard actionIcon="none" color="dark" title="Clinical Data Analytics - Coming Soon" mdxType="ResourceCard">
        </ResourceCard>
      </Column>
      <Column colMd={6} colLg={9} noGutterMdLeft mdxType="Column">
        <p>{`The `}<em parentName="p">{`Clinical Data Analytics`}</em>{` reference implementation will incorporate various types of analytics using Kubeflow.  To begin, we will incorporate all of the analytics found in the `}<em parentName="p">{`Enrichment`}</em>{` pattern into Kubeflow by calling the same set of Microservices that were used by Enrichment.`}</p>
        <p>{`We will have examples of `}</p>
        <ul>
          <li parentName="ul">{`Using single patient data from the FHIR server to score against a model`}</li>
          <li parentName="ul">{`Bulk export data from the FHIR Server, transform the data and run the model/analytic in Kubeflow pipelines against multiple patients`}</li>
          <li parentName="ul">{`Bulk export data from the FHIR Server and transform it to train a new model`}</li>
          <li parentName="ul">{`Serving an existing trained model`}</li>
        </ul>
        <p />
      </Column>
    </Row>
    <p />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      